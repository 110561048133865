import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Limitations = {
  certificates: number;
  duties: number;
  education: number;
  employment_history: number;
  licences: number;
  skills: number;
};
type QueryError = {};

const useResumeLimitationsQuery = (
  options?: UseQueryOptions<Limitations, QueryError, Limitations, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const url = `${GATEWAY_URL}/profiles/config/resume/limits`;
  const cacheKey = [url];

  return useQuery<Limitations, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<Limitations>(url);
    },
    {
      ...options,
    }
  );
};
export default useResumeLimitationsQuery;
