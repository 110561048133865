import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type EducationItem = Partial<{
  title: string | null;
  institution: string | null;
  date_from: number | null;
  date_to: number | null;
}>;

type Education = Array<EducationItem>;

type UpdateEducationVariables = {
  education: Education;
  resumeId?: number;
};

type UpdateEducationResult = {
  cid: number;
  resumeId: number;
  success: boolean;
};

type QueryError = {};

const useUpdateEducationMutation = (
  options?: UseMutationOptions<
    UpdateEducationResult,
    QueryError,
    UpdateEducationVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();

  return {
    ...useMutation<UpdateEducationResult, QueryError, UpdateEducationVariables>(
      async ({ resumeId, education }: UpdateEducationVariables) => {
        const cid = personalDetails?.traveller?.id;

        if (!cid) throw new Error("cid not found");
        if (!resumeId) throw new Error("resumeId not found");

        const url = `${GATEWAY_URL}/profiles/v2/${cid}/resumes/${resumeId}/education`;

        return fetchWithAuth<UpdateEducationResult>(url, {
          method: "POST",
          body: education,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateEducationMutation;
