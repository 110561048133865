import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Certificate = Partial<{
  details: string;
}>;

type Certificates = Array<Certificate>;

type UpdateCertificatesVariables = {
  certificates: Certificates;
  resumeId?: number;
};

type UpdateCertificatesResult = {
  cid: number;
  resumeId: number;
  success: boolean;
};

type QueryError = {};

const useUpdateCertificatesMutation = (
  options?: UseMutationOptions<
    UpdateCertificatesResult,
    QueryError,
    UpdateCertificatesVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();

  return {
    ...useMutation<
      UpdateCertificatesResult,
      QueryError,
      UpdateCertificatesVariables
    >(
      async ({ resumeId, certificates }: UpdateCertificatesVariables) => {
        const cid = personalDetails?.traveller?.id;

        if (!cid) throw new Error("cid not found");
        if (!resumeId) throw new Error("resumeId not found");

        const url = `${GATEWAY_URL}/profiles/v2/${cid}/resumes/${resumeId}/certificates`;

        return fetchWithAuth<UpdateCertificatesResult>(url, {
          method: "POST",
          body: certificates,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateCertificatesMutation;
