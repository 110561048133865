import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { useEffect } from "react";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";

export enum PdfCodes {
  Vancouver = "vancouver",
  NewYork = "new york",
  London = "london",
  Melbourne = "melbourne",
}

type Input = {
  code: PdfCodes;
  color: string;
  showEmail: boolean;
  showSkype: boolean;
  showPhone: boolean;
};

type PdfResult = {
  success: true;
  url: string;
};
type QueryError = {};

const useResumePdfQuery = (
  input: Input,
  options?: UseQueryOptions<PdfResult, QueryError, PdfResult, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { currentTrip } = useCurrentTrip();
  const cid = currentTrip?.cid;
  const url = `${GATEWAY_URL}/profiles/${cid}/resumes/pdf`;
  const cacheKey = [url, input.code, input.color];

  return useQuery<PdfResult, QueryError>(
    cacheKey,
    async () => {
      return fetchWithAuth<PdfResult>(url, { params: input });
    },
    {
      ...options,
    }
  );
};
export default useResumePdfQuery;
