import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type ResumeSortableList = Array<
  Partial<{ id?: number; details?: string | null; order_number?: number }>
>;

type ResumeEducation = Partial<
  Array<{
    date_from: string | null;
    date_to: string | null;
    id: number;
    institution: string | null;
    order_num: number;
    title: string | null;
  }>
>;

type EmploymentHistoryDuties = Partial<
  Array<{
    details: string | null;
    employment_id: number | null;
    id: number;
    order_num: number;
    resume_id: number;
  }>
>;

type ResumeEmploymentHistory = Partial<
  Array<{
    company: string | null;
    title: string | null;
    website: string | null;
    currently_employed: boolean;
    date_from: string | null;
    date_to: string | null;
    duties: EmploymentHistoryDuties;
    id: number;
    order_num: number;
  }>
>;

export enum ResumeStatus {
  Pending = "Pending",
  Approved = "Approved",
  Draft = "Draft",
  RequestedChanges = "Requested Changes",
}

type Resume = {
  resume: {
    cid: number;
    email: string;
    first_name: string;
    id: number;
    introduction: string;
    last_name: string;
    middle_name: string;
    objectives: string;
    phone: string;
    phone_code: string;
    skype: string;
    status: ResumeStatus;
    step_index: number;
    s3_resume_pic: string | null;
    version: number;
    professional_summary: string;
  };
  skills: ResumeSortableList;
  certificates: ResumeSortableList;
  licences: ResumeSortableList;
  education: ResumeEducation;
  employment_history: ResumeEmploymentHistory;
  request_for_change: Partial<{
    description: string | null;
    id: number;
    requested_at: number;
    requested_by: string | null;
    resume_id: number;
    type: string | null;
  }>;
};
type QueryError = {};

const useResumeQuery = (
  options?: UseQueryOptions<Resume, QueryError, Resume, QueryKey>
) => {
  const { fetchWithAuth } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const cid = personalDetails?.traveller?.id;

  const url = `${GATEWAY_URL}/profiles/${cid}/resumes`;
  const cacheKey = [url];

  return useQuery<Resume, QueryError>(
    cacheKey,
    async () => {
      if (!cid) {
        throw new Error("cid not found");
      }
      return fetchWithAuth<Resume>(url);
    },
    {
      enabled: !!cid,
      ...options,
    }
  );
};
export default useResumeQuery;
