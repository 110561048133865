import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";
import { useCurrentTrip } from "@/hooks";
import { ResumeStatus } from "./useResumeQuery";

type ResumeDetails = Partial<{
  status: ResumeStatus | null;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  email: string | null;
  phone_code: string | null;
  phone: string | null;
  skype: string | null;
  introduction: string | null;
  objectives: string | null;
  step_index: number;
  professional_summary: string | null;
}>;

type UpdateResumeDetailsVariables = {
  resume: ResumeDetails;
  resumeId?: number;
};

type UpdateResumeDetailsResult = {
  cid: number;
  resumeId: number;
  success: boolean;
};

type QueryError = {};

const useUpdateResumeDetailsMutation = (
  options?: UseMutationOptions<
    UpdateResumeDetailsResult,
    QueryError,
    UpdateResumeDetailsVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();
  const { currentTrip } = useCurrentTrip();

  return {
    ...useMutation<
      UpdateResumeDetailsResult,
      QueryError,
      UpdateResumeDetailsVariables
    >(async ({ resumeId, resume }: UpdateResumeDetailsVariables) => {
      const cid = personalDetails?.traveller?.id;
      const tid = currentTrip?.id;

      if (!cid) throw new Error("cid not found");
      if (!tid) throw new Error("tid not found");
      if (!resumeId) throw new Error("resumeId not found");

      const url = `${GATEWAY_URL}/profiles/${cid}/resumes/${resumeId}/${tid}`;

      return fetchWithAuth<UpdateResumeDetailsResult>(url, {
        method: "POST",
        body: resume,
      });
    }, options),
    cancel,
  };
};

export default useUpdateResumeDetailsMutation;
