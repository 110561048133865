import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type UpgradeVersionVariables = {
  resumeId: number;
  first_name: string;
  step_index: number;
};

type UpgradeVersionResult = {
  success: boolean;
  result: {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
  };
};

type QueryError = {};

const useUpgradeVersionMutation = (
  options?: UseMutationOptions<
    UpgradeVersionResult,
    QueryError,
    UpgradeVersionVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();

  return {
    ...useMutation<UpgradeVersionResult, QueryError, UpgradeVersionVariables>(
      async ({ resumeId, first_name, step_index }: UpgradeVersionVariables) => {
        const cid = personalDetails?.traveller?.id;

        if (!cid) throw new Error("cid not found");
        if (!resumeId) throw new Error("resumeId not found");

        const url = `${GATEWAY_URL}/profiles/${cid}/resumes/${resumeId}/version-upgrade`;

        return fetchWithAuth<UpgradeVersionResult>(url, {
          method: "PUT",
          body: { first_name, step_index },
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpgradeVersionMutation;
