import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Licence = Partial<{
  details: string;
}>;

type Licences = Array<Licence>;

type UpdateLicencesVariables = {
  licences: Licences;
  resumeId?: number;
};

type UpdateLicencesResult = {
  cid: number;
  resumeId: number;
  success: boolean;
};

type QueryError = {};

const useUpdateLicencesMutation = (
  options?: UseMutationOptions<
    UpdateLicencesResult,
    QueryError,
    UpdateLicencesVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();

  return {
    ...useMutation<UpdateLicencesResult, QueryError, UpdateLicencesVariables>(
      async ({ resumeId, licences }: UpdateLicencesVariables) => {
        const cid = personalDetails?.traveller?.id;

        if (!cid) throw new Error("cid not found");
        if (!resumeId) throw new Error("resumeId not found");

        const url = `${GATEWAY_URL}/profiles/v2/${cid}/resumes/${resumeId}/licences`;

        return fetchWithAuth<UpdateLicencesResult>(url, {
          method: "POST",
          body: licences,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateLicencesMutation;
