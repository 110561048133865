import { useMutation, UseMutationOptions } from "react-query";
import { GATEWAY_URL } from "@/fetch/profiles/constants";
import { usePersonalDetailsQuery } from "@/fetch/profiles";
import useFetchWithAuth from "@/fetch/fetchWithAuth";

type Skill = Partial<{
  details: string;
}>;

type Skills = Array<Skill>;

type UpdateSkillsVariables = {
  skills: Skills;
  resumeId?: number;
};

type UpdateSkillsResult = {
  cid: number;
  resumeId: number;
  success: boolean;
};

type QueryError = {};

const useUpdateSkillsMutation = (
  options?: UseMutationOptions<
    UpdateSkillsResult,
    QueryError,
    UpdateSkillsVariables
  >
) => {
  const { fetchWithAuth, cancel } = useFetchWithAuth();
  const { data: personalDetails } = usePersonalDetailsQuery();

  return {
    ...useMutation<UpdateSkillsResult, QueryError, UpdateSkillsVariables>(
      async ({ resumeId, skills }: UpdateSkillsVariables) => {
        const cid = personalDetails?.traveller?.id;

        if (!cid) throw new Error("cid not found");
        if (!resumeId) throw new Error("resumeId not found");

        const url = `${GATEWAY_URL}/profiles/v2/${cid}/resumes/${resumeId}/skills`;

        return fetchWithAuth<UpdateSkillsResult>(url, {
          method: "POST",
          body: skills,
        });
      },
      {
        ...options,
      }
    ),
    cancel,
  };
};

export default useUpdateSkillsMutation;
